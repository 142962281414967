// app.js

class App {

  /**
   * Options object
   * @type {Object}
   */
  _options = {};

  /**
   * Constructor
   * @param  {Object} options Options object
   * @return {null}
   */
  constructor(options = {}) {
    this._options = options;
    //
    document.addEventListener('DOMContentLoaded', () => {
      this.onDomReady();
    }, false);

    document.addEventListener('turbo:submit-start', ({ target }) => {
      if ( target.hasAttribute('x-bulk') ) {
        const checkboxes = document.querySelectorAll('.table-entities input[type=checkbox]');
        checkboxes.forEach((checkbox) => {
          if (checkbox.checked) {
            event.detail.formSubmission.fetchRequest.body.append('selected[]', checkbox.value);
          }
        });
      }
      for (const element of target.elements) {
        element.disabled = true;
        if (element.type == 'submit') {
          element.innerHTML = `<i class="fa-solid fa-sync fa-spin"></i> ${element.innerText}`;
        }
      }
    });

    document.addEventListener('turbo:before-fetch-request', ({ detail }) => {
      if (detail.fetchOptions.method == 'POST') {
        // console.log('disable other forms');
      }
    });

    document.addEventListener('turbo:before-fetch-response', ({ detail }) => {
      // console.log('enable other forms');
    });

    document.addEventListener('turbo:frame-render', ({ detail }) => {
      const meta = document.querySelector('meta[name=csrf-token]');
      meta.content = detail.fetchResponse.response.headers.get('x-csrf-token');
    });

    document.addEventListener('turbo:frame-missing', (event) => {
      event.preventDefault()
      event.detail.visit(window.location.href)
    });

    document.addEventListener('turbo:render', (event) => {
      this.onDomReady();
    });

    document.addEventListener('alpine:init', () => {
      Alpine.directive('markdown', (el, { modifiers }, { cleanup }) => {
        const textarea = $(el),
          wrapper = $('<div class="markdown"></div>'),
          source = $('<div class="markdown-source"></div>'),
          preview = $('<div class="markdown-preview"></div>'),
          panel = $('<div class="markdown-panel"></div>'),
          toolbar = $('<div class="markdown-toolbar"><i class="fa-brands fa-markdown me-1"></i><span>MarkDown styling</span><a href="#" class="toggle">Preview</a></div>');
        textarea.wrap(source);
        source.wrap(panel);
        panel.wrap(wrapper);
        panel.append(preview);
        wrapper.append(toolbar);

        const editor = ace.edit(el);
        editor.setOption('highlightActiveLine', false);
        editor.session.setOption('mode', 'ace/mode/markdown');
        editor.session.setOption('wrap', true);
        editor.renderer.setOption('fontSize', 13);
        editor.renderer.setOption('fontFamily', 'Noto Sans Mono');
        editor.renderer.setOption('theme', 'ace/theme/github');
        editor.renderer.setOption('showGutter', false);
        editor.renderer.setOption('printMargin', false);

        toolbar.on('click', 'a', (e) => {
          const el = $(e.currentTarget);
          e.preventDefault();
          el.toggleClass('active');
          preview.html( marked.parse( editor.getValue() ) );
          preview.toggleClass('active');
          editor.resize();
        });
      });
    });
  }

  /**
   * On DOM ready callback
   * @return {null}
   */
  onDomReady() {
    // hljs.highlightAll();
  }

  slugifyString(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
    return str;
  }

  slugifyValue(event) {
    const target = document.querySelector(this.slugify);
    if (event.target.value && !target.value) {
      target.value = window.moritaBox.slugifyString(event.target.value);
    }
  }

  setPlaceholderAsValue(event) {
    this.placeholder.forEach(selector => {
      const target = document.querySelector(selector);
      if (! target.value ) {
        target.value = target.placeholder;
      }
    });
    event.target.setAttribute('disabled', '');
  }

  copyText(event) {
    navigator.clipboard.writeText(this.text).then(() => {
      const originalClass = event.target.className;
      event.target.className = 'fa-solid fa-circle-check text-success';
      setTimeout(() => {
        event.target.className = originalClass;
      }, 500);
    }, () => {
      console.error('Failed to copy');
    });
  }

  toggleSelection(event) {
    const checkboxes = document.querySelectorAll('.table-entities input[type=checkbox]');
    const allChecked = [...checkboxes].every(checkbox => checkbox.checked === true)
    checkboxes.forEach(checkbox => checkbox.checked = !allChecked);
  }
}

window.app = window.app || new App();
